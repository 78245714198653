"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = SearchPagePI;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _api = require("@whitbread-eos/api");
var _atoms = require("@whitbread-eos/atoms");
var _molecules = require("@whitbread-eos/molecules");
var _organisms = require("@whitbread-eos/organisms");
var _utils = require("@whitbread-eos/utils");
var _react2 = require("react");
var _utilities = require("./utilities");
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function SearchPagePI(_ref) {
  var _router$query;
  var {
    router,
    queryClient
  } = _ref;
  var [showNoHotelsWarning, setShowNoHotelsWarning] = (0, _react2.useState)(false);
  var multiSearchParams = (0, _organisms.getSearchParams)(router.query);
  var {
    placeId,
    coordinates,
    location
  } = multiSearchParams;
  var {
    language,
    country
  } = (0, _utils.useCustomLocale)();
  (0, _react2.useEffect)(() => {
    if (!placeId && !coordinates) {
      (0, _api.getSuggestions)(location).then(response => {
        var newQuery = (0, _organisms.getSearchResultsURLQuery)(multiSearchParams, response, router.query);
        return router.push({
          pathname: "".concat(country, "/").concat(language).concat(router.pathname, ".html"),
          query: newQuery,
          search: undefined
        }, undefined, {
          shallow: true
        });
      }).catch(error => {
        console.log(error);
      });
    }
  }, [placeId, coordinates, location]);
  var defaultRooms = (0, _utilities.getDefaultRooms)(router.query);
  var {
    arrivalDay: ARRdd,
    arrivalMonth: ARRmm,
    arrivalYear: ARRyyyy,
    numberOfNights: NIGHTS,
    rooms: ROOMS
  } = multiSearchParams;
  var noHotelsWarningHandle = value => {
    setShowNoHotelsWarning(value);
  };
  var isMetaSearch = 'WEB' !== ((_router$query = router.query) === null || _router$query === void 0 ? void 0 : _router$query.BOOKINGCHANNEL);
  return (0, _jsxRuntime.jsxs)(_react.Flex, _objectSpread(_objectSpread({}, containerStyles), {}, {
    children: [(0, _jsxRuntime.jsx)(_molecules.SEO, {
      page: _api.PageName.SRP,
      displayMeta: true
    }), (0, _jsxRuntime.jsx)(_atoms.ErrorBoundary, {
      noContentBoundary: true,
      children: (0, _jsxRuntime.jsx)(_react.Box, _objectSpread(_objectSpread({}, searchContainerStyles), {}, {
        children: (0, _jsxRuntime.jsx)(_organisms.PISearchContainer, {
          queryClient: queryClient,
          isSummaryActive: !isMetaSearch,
          defaultLocation: location,
          defaultRooms: defaultRooms,
          ARRdd: Number(ARRdd),
          ARRmm: Number(ARRmm),
          ARRyyyy: Number(ARRyyyy),
          NIGHTS: Number(NIGHTS),
          ROOMS: Number(ROOMS),
          showNoHotelsWarning: showNoHotelsWarning
        })
      }))
    }), (0, _jsxRuntime.jsx)(_atoms.ErrorBoundary, {
      children: (0, _jsxRuntime.jsx)(_organisms.SearchResultsPIVariant, {
        queryClient: queryClient,
        multiSearchParams: multiSearchParams,
        onNoHotelsWarning: noHotelsWarningHandle
      })
    })]
  }));
}
var searchContainerStyles = {
  maxWidth: {
    mobile: '100%',
    lg: 'var(--chakra-space-breakpoint-lg)',
    xl: 'var(--chakra-space-breakpoint-xl)'
  },
  px: {
    mobile: '1rem',
    sm: '1.25rem',
    md: '1.5rem',
    lg: '1.75rem',
    xl: '4.125rem'
  },
  paddingTop: 'var(--chakra-space-sm)',
  mx: 'auto',
  width: '100%'
};
var containerStyles = {
  height: '100%',
  display: 'flex',
  flexDirection: 'column'
};