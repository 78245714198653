"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = SearchPageCCUI;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _atoms = require("@whitbread-eos/atoms");
var _organisms = require("@whitbread-eos/organisms");
var _react2 = require("react");
var _utilities = require("./utilities");
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function SearchPageCCUI(_ref) {
  var {
    router,
    queryClient
  } = _ref;
  var multiSearchParams = (0, _organisms.getSearchParams)(router.query);
  var [isSearchError, setIsSearchError] = (0, _react2.useState)(true);
  var defaultRooms = (0, _utilities.getDefaultRooms)(router.query);
  var {
    reservationId
  } = router.query;
  var {
    location,
    arrivalDay: ARRdd,
    arrivalMonth: ARRmm,
    arrivalYear: ARRyyyy,
    numberOfNights: NIGHTS,
    rooms: ROOMS,
    corpId: CORPID
  } = multiSearchParams;
  return (0, _jsxRuntime.jsxs)(_react.Flex, _objectSpread(_objectSpread({}, containerStyles), {}, {
    children: [(0, _jsxRuntime.jsx)(_atoms.ErrorBoundary, {
      noContentBoundary: true,
      children: (0, _jsxRuntime.jsx)(_react.Box, _objectSpread(_objectSpread({}, searchContainerStyles), {}, {
        children: (0, _jsxRuntime.jsx)(_organisms.CCUISearchContainer, {
          queryClient: queryClient,
          isSummaryActive: true,
          defaultLocation: location,
          defaultRooms: defaultRooms,
          ARRdd: Number(ARRdd),
          ARRmm: Number(ARRmm),
          ARRyyyy: Number(ARRyyyy),
          NIGHTS: Number(NIGHTS),
          ROOMS: Number(ROOMS),
          CORPID: CORPID,
          setIsSearchError: setIsSearchError,
          prevReservationId: reservationId ? String(reservationId) : undefined
        })
      }))
    }), (0, _jsxRuntime.jsx)(_atoms.ErrorBoundary, {
      children: (0, _jsxRuntime.jsx)(_organisms.SearchResultsCCUIVariant, {
        multiSearchParams: multiSearchParams,
        queryClient: queryClient,
        isSearchError: isSearchError
      })
    })]
  }));
}
var containerStyles = {
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  mt: 'xl'
};
var searchContainerStyles = {
  maxWidth: {
    lg: 'var(--chakra-space-breakpoint-lg)',
    xl: 'var(--chakra-space-breakpoint-xl)'
  },
  px: {
    lg: '1.75rem',
    xl: '4.125rem'
  },
  mx: 'auto',
  width: '100%'
};