"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getDefaultRooms = getDefaultRooms;
function getDefaultRooms(query) {
  var noOfRooms = Number(query.ROOMS);
  if (!noOfRooms) {
    return [{
      adults: 1,
      children: 0,
      shouldIncludeCot: false,
      roomType: 'DB'
    }];
  }
  var defaultRooms = [];
  for (var idx = 0; idx < noOfRooms; idx++) {
    var defaultRoom = {
      adults: Number(query["ADULT".concat(idx + 1)]),
      children: Number(query["CHILD".concat(idx + 1)]),
      shouldIncludeCot: !!Number(query["COT".concat(idx + 1)]),
      roomType: query["INTTYP".concat(idx + 1)]
    };
    defaultRooms.push(defaultRoom);
  }
  return defaultRooms;
}