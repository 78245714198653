"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = Page;
var _reactQuery = require("@tanstack/react-query");
var _page = _interopRequireDefault(require("./page.bb"));
var _page2 = _interopRequireDefault(require("./page.ccui"));
var _page3 = _interopRequireDefault(require("./page.pi"));
var _jsxRuntime = require("react/jsx-runtime");
function Page(_ref) {
  var {
    router,
    queryClient,
    variant,
    accessLevel
  } = _ref;
  return (0, _jsxRuntime.jsx)(_reactQuery.QueryClientProvider, {
    client: queryClient,
    children: renderPage(variant)
  });
  function renderPage(variant) {
    switch (variant) {
      case 'pi':
        {
          return (0, _jsxRuntime.jsx)(_page3.default, {
            router: router,
            queryClient: queryClient
          });
        }
      case 'ccui':
        {
          return (0, _jsxRuntime.jsx)(_page2.default, {
            router: router,
            queryClient: queryClient
          });
        }
      case 'bb':
        {
          return (0, _jsxRuntime.jsx)(_page.default, {
            router: router,
            queryClient: queryClient,
            variant: "bb",
            accessLevel: accessLevel
          });
        }
      default:
        {
          return (0, _jsxRuntime.jsx)(_page3.default, {
            router: router,
            queryClient: queryClient
          });
        }
    }
  }
}