"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _reactQuery = require("@tanstack/react-query");
var _api = require("@whitbread-eos/api");
var _organisms = require("@whitbread-eos/organisms");
var _utils = require("@whitbread-eos/utils");
var _add = _interopRequireDefault(require("date-fns/add"));
var _format = _interopRequireDefault(require("date-fns/format"));
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
var createSearchResultsCCUIDataLoader = function () {
  var _ref2 = (0, _asyncToGenerator2.default)(function* (_ref) {
    var _multiSearchParams$pl;
    var {
      session,
      queryClient,
      language,
      country,
      query,
      proxyOptions,
      req,
      res
    } = _ref;
    var {
      prefetchQuery,
      logQueries
    } = new _utils.QueriesLogger(queryClient, req, res, query, 'CCUI | SRP | Search Results Page', session === null || session === void 0 ? void 0 : session.user.name);
    var user = session === null || session === void 0 ? void 0 : session.user;
    var roles = session === null || session === void 0 ? void 0 : session.user[_api.CCUI_ROLES];
    var multiSearchParams = (0, _organisms.getSearchParams)(query);
    var isValidDate = (0, _utils.isDateValid)(Number(multiSearchParams.arrivalDay), Number(multiSearchParams.arrivalMonth), Number(multiSearchParams.arrivalYear));
    var startDate = isValidDate ? new Date(Number(multiSearchParams.arrivalYear), Number(multiSearchParams.arrivalMonth && multiSearchParams.arrivalMonth - 1), Number(multiSearchParams.arrivalDay)) : new Date();
    var place = {
      location: (_multiSearchParams$pl = multiSearchParams.placeId) !== null && _multiSearchParams$pl !== void 0 ? _multiSearchParams$pl : multiSearchParams.coordinates,
      locationFormat: multiSearchParams.placeId ? 'PLACEID' : 'LATLONG',
      radius: language === 'en' ? 30 : 50,
      radiusUnit: (0, _utils.getDistanceUnitBasedOnLocale)(language)
    };
    var endDate = (0, _add.default)(startDate, {
      days: Number(multiSearchParams.numberOfNights)
    });
    var paramsForQuery = {
      startDate: (0, _format.default)(startDate, 'yyyy-MM-dd'),
      endDate: (0, _format.default)(endDate, 'yyyy-MM-dd'),
      rooms: multiSearchParams.rooms,
      place,
      oldWorldChannel: multiSearchParams.bookingChannel,
      channel: _organisms.APP_VARIANT.CCUI,
      subChannel: multiSearchParams.bookingChannel,
      page: 1,
      initialPageSize: '40',
      lazyLoadPageSize: '10',
      country,
      language,
      sort: multiSearchParams.sort,
      companyId: multiSearchParams.corpId
    };
    var searchInformationQuery = prefetchQuery(['searchInformation', paramsForQuery.country, paramsForQuery.language], () => (0, _utils.graphQLRequest)(_api.SEARCH_INFORMATION_RESULTS, {
      country: paramsForQuery.country,
      language: paramsForQuery.language
    }, undefined, proxyOptions));
    var hotelAvailablilitiesQueryKey = ['hotelAvailabilities', paramsForQuery.startDate, paramsForQuery.endDate, paramsForQuery.rooms, paramsForQuery.place, paramsForQuery.oldWorldChannel, paramsForQuery.channel, paramsForQuery.subChannel, paramsForQuery.page, paramsForQuery.initialPageSize, paramsForQuery.lazyLoadPageSize, paramsForQuery.country, paramsForQuery.language, paramsForQuery.sort];
    var hotelAvailabilitiesCcuiQuery = prefetchQuery(paramsForQuery.companyId ? [...hotelAvailablilitiesQueryKey, paramsForQuery.companyId] : hotelAvailablilitiesQueryKey, () => (0, _utils.graphQLRequest)(_api.MULTI_HOTEL_AVAILABILITIES_QUERY, _objectSpread({}, paramsForQuery), undefined, proxyOptions));
    var getStaticContentQuery = prefetchQuery(['GetStaticContent', language, country], () => (0, _utils.graphQLRequest)(_api.GET_STATIC_CONTENT, {
      language,
      country,
      site: _api.SITE_LEISURE,
      businessBooker: false
    }, undefined, proxyOptions));
    var searchRulesQuery = prefetchQuery(['getSearchRules', 'PI'], () => (0, _utils.graphQLRequest)(_api.GET_SEARCH_RULES_QUERY, {
      channel: 'CCUI'
    }));
    var {
      corpId
    } = multiSearchParams;
    if (corpId) {
      try {
        yield prefetchQuery(['searchCompanyById', corpId], () => (0, _utils.graphQLRequest)(_api.SEARCH_COMPANY_BY_ID, {
          corpId
        }));
      } catch (error) {
        _utils.logger.error(error);
      }
    }
    yield Promise.all([getStaticContentQuery, hotelAvailabilitiesCcuiQuery, searchInformationQuery, searchRulesQuery]);
    logQueries(performance.now());
    return {
      dehydratedState: (0, _reactQuery.dehydrate)(queryClient),
      user,
      roles
    };
  });
  return function createSearchResultsCCUIDataLoader(_x) {
    return _ref2.apply(this, arguments);
  };
}();
var _default = exports.default = createSearchResultsCCUIDataLoader;